
import { defineComponent, ref } from 'vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'

export default defineComponent({
  components: {
    TmFormLine,
  },
  setup() {
    const date = ref(new Date('2022-02-17T00:00:00'))
    const dateEmpty = ref<Date | null>(null)
    const dueDate = ref({
      date: new Date('2022-02-17T14:24:00'),
      reminder: '',
    })
    const dueDateEmpty = ref({
      date: null,
      reminder: '',
    })
    const timepicker = ref()
    const reminder = ref('')

    return {
      date,
      dateEmpty,
      dueDate,
      timepicker,
      dueDateEmpty,
      reminder,
    }
  },
})
